import React from 'react';
import Reveal from 'react-reveal/Reveal';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Calculator from '../../components/Service/ProcessAutomation/calculator';
import urls from '../../data/urls';

const ProcessAutomation = () => {
  const imgData = useStaticQuery(graphql`
    query {
      contentfulAsset(title: {eq: "automation"}) {
        fluid {
          aspectRatio
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
    }
  `);
  const image = imgData.contentfulAsset.fluid;

  return (
    <div>
      <Reveal bottom cascade>
        <div className="container">
          <div className="app_featured_content row">
            <div className="col-lg-5 d-flex align-items-center" >
              <Img fluid={image} className="w-75 mx-auto"/>
            </div>
            <div className="col-lg-7" >
              <h2 className="f_p f_size_30 f_700 t_color3 l_height45 my-4">Maximize Efficiency For Your Team</h2>
              <p className="f_400">
                The future is here, and while the Jetson's flying cars are not waiting in the garage, we are in the company of robotic bartenders and AI tax assistants. Don't let your company fall behind the times. Our automation specialists will assess your current processes to design, build, and maintain systems that will improve the speed, accuracy, and ease of outdated workflows.
              </p>
              <p className="f_400 mt_40">
                Schedule a meeting now for a free process audit. We will interview a select number of your employees to generate an estimate of your wasted time, along with a proposal for which tasks could be easily automated as we help your company stay competitive into the future.
              </p>
              <Link to={urls.contactUs} className="app_btn btn_hover mt_40">Get A Quote!</Link>
              <p className="f_400 mt_40">
                Fill out the cost savings calculator below to estimate how much your company is spending every year on repetitive tasks.
              </p>
            </div>
          </div>
        </div>
      </Reveal>
      <Calculator />
    </div>
  );
}

export default ProcessAutomation;
