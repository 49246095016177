import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const Calculator = () => {
  const [headCount, setHeadCount] = useState(50);
  const [time, setTime] = useState(6);
  const [salary, setSalary] = useState(52000);

  const imgData = useStaticQuery(graphql`
    query {
      contentfulAsset(title: {eq: "automation-calculator"}) {
        fluid {
          aspectRatio
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
    }
  `);
  const image = imgData.contentfulAsset.fluid;

  const getCost = () => {
    const hourly = (salary * 1.2) / 2080;
    const total = headCount * time * 52 * hourly;
    return total;
  }

  return (
    <section className="contact_info_area sec_pad container row mx-auto">
      <div className="col-lg-6 bg_color">
      <h2 className="f_p f_size_22 t_color3 f_600 l_height28 my-4">Time Savings Calculator</h2>
        <form>
          <div className="column form-group text_box">
            <label htmlFor="headcount">
              Headcount
              <input
                type="number"
                name="headcount"
                placeholder="total number of workers"
                onChange={(e) => setHeadCount(e.target.value)}
                value={headCount}
              />
            </label>
            <p>Include full time employees and contractors.</p>
          </div>
          <div className="column form-group text_box">
            <label htmlFor="salary">
              Average Salary
              <input
                type="number"
                name="salary"
                placeholder="average full time employee salary"
                onChange={(e) => setSalary(e.target.value)}
                value={salary}
              />
            </label>
          </div>
          <div className="column form-group text_box">
            <label htmlFor="time">
              Estimated hours per week spent on repetitive tasks per employee.
              <input
                type="number"
                name="time"
                placeholder="time spent on repetitive tasks"
                onChange={(e) => setTime(e.target.value)}
                value={time}
              />
            </label>
            <p>According to a study by Smartsheets the average worker spends 6 hours per week on easily automatable tasks.</p>
          </div>
          <div>
            <h2 className="f_p f_size_22 t_color3 f_600 l_height28 mb_40">Total Annual Cost for Automatable Tasks</h2>
            <h3>{`$${getCost()}`}</h3>
          </div>
        </form>
      </div>
      <div className="col-lg-6 d-flex align-items-center" >
        <Img fluid={image} className="w-75 mx-auto"/>
      </div>
    </section>
  )
}

export default Calculator;
